<template>
  <div class="wrap">
    <div class="nav">
      <div class="navList">
        <div @click="jump('1')">
          <span>首页</span>
          <span>Home</span>
        </div>
        <div @click="jump('2')">
          <span>服务案例</span>
          <span>Case</span>
        </div>
        <div @click="jump('3')">
          <span>新闻资讯</span>
          <span>News</span>
        </div>
        <div @click="jump('4')">
          <span>业务伙伴</span>
          <span>Cooperation</span>
        </div>
        <div @click="jump('5')" class="cur">
          <span>关于我们</span>
          <span>About</span>
        </div>
      </div>
    </div>
    <div class="text">
      <div class="left"></div>
      <div class="right">
        <img
          src="../../public/imgs/me/ABOUTUS.png"
          alt=""
          style="margin-top: 90px"
        />
        <div>关于我们</div>
        <div class="line"></div>
        <div class="test">
          专注于办公室、高端写字楼、家居别墅，以及软装配饰、智能化工程、园林景观、消防、弱点一体化增值服务。我们不仅仅只做装饰，更致力于深度挖掘空间的商业价值，帮助客户将空间价值发挥至极致。
        </div>
        <div class="test">
          创星装饰成立以来，建立了一支实力雄厚的设计团队、正规的施工队伍和专业的质检人员，目前主营业务为室内外装饰设计，施工及维护；银行类智慧服务厅施工维护、建筑工程和安装工程，先后被中国建筑装饰协会评为AAA级信用企业，并取得ISO9001质量管理体系认证、环境管理体系、职业健康安全管理体系、建筑业企业资质以及安全生产许可证证书。
        </div>
      </div>
    </div>
    <div class="title">
      <img src="../../public/imgs/me/team.png" alt="" />
      <div></div>
      <div>
        我们拥有一支富有创意的优秀室内设计团队，确保每个装修案例都经过设计师的精心设计，工匠们的用心施工。公司从规范运作入手，严格装饰施工程序，透明、合理的报价单是创星装饰的一大特色，在客户群中形成良好的口碑，
        团队至今为止，在质量和服务品质上都始终保持“零”投诉。
      </div>
    </div>
    <div class="zxxm">
      <div class="zxxmB1">
        <div>
          <img src="../../public/imgs/me/1.png" alt="" />
          <p>金融/地产</p>
        </div>
        <div>
          <img src="../../public/imgs/me/2.png" alt="" />
          <p>信息/科技</p>
        </div>
        <div>
          <img src="../../public/imgs/me/3.png" alt="" />
          <p>设计/传媒</p>
        </div>
        <div>
          <img src="../../public/imgs/me/4.png" alt="" />
          <p>服务/管理</p>
        </div>
        <div>
          <img src="../../public/imgs/me/5.png" alt="" />
          <p>教育/医疗</p>
        </div>
      </div>
      <div class="zxxmB2">
        <div>
          <img src="../../public/imgs/me/6.png" alt="" />
          <p>贸易/物流</p>
        </div>
        <div>
          <img src="../../public/imgs/me/7.png" alt="" />
          <p>制造/能源</p>
        </div>
        <div>
          <img src="../../public/imgs/me/8.png" alt="" />
          <p>影音/娱乐</p>
        </div>
        <div>
          <img src="../../public/imgs/me/9.png" alt="" />
          <p>总部/大楼</p>
        </div>
      </div>
    </div>
    <img src="../../public/imgs/me/zizhiTitle.png" alt="" />
    <div class="zizhi">
      <div class="main">
        <vue-seamless-scroll
          :data="zizhiList"
          class="seamless-warp"
          :class-option="classOption"
        >
          <img
            :src="item"
            alt=""
            v-for="(item, index) in zizhiList"
            :key="index"
          />
        </vue-seamless-scroll>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/Footer";
import z1 from "../../public/imgs/me/z1.png";
import z2 from "../../public/imgs/me/z2.jpg";
import z3 from "../../public/imgs/me/z3.jpg";
import z4 from "../../public/imgs/me/z4.jpg";
import z5 from "../../public/imgs/me/z5.jpg";
import z6 from "../../public/imgs/me/z6.jpg";
import z7 from "../../public/imgs/me/z7.jpg";
import z8 from "../../public/imgs/me/z8.jpg";
export default {
  name: "index",
  components: {
    Footer,
  },
  data() {
    return {
      zizhiList: [z1, z2, z3, z4, z5, z6, z7, z8],
      classOption: {
        step: 1,
        limitMoveNum: 8, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 3, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
    };
  },
  mounted() {},
  methods: {
    jump(index) {
      if (index == 1) {
        this.$router.push({
          path: "/",
        });
      }
      if (index == 2) {
        this.$router.push({
          path: "/case",
        });
      }
      if (index == 3) {
        this.$router.push({
          path: "/newsInfo",
        });
      }
      if (index == 4) {
        this.$router.push({
          path: "/partner",
        });
      }
      if (index == 5) {
        this.$router.push({
          path: "/me",
        });
      }
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.wrap {
  height: 100vh;
  width: 1920px;
  margin: 0 auto;
}
.cur {
  color: #028ccd !important;
}
.nav .navList {
  position: absolute;
  top: 25px;
  right: 300px;
  height: 50px;
}
/* 导航 */
.nav {
  width: 100%;
  height: 100px;
  background: #fff url("../../public/imgs/indexNew/logo.png") no-repeat 76px
    center;
  overflow: hidden;
  cursor: pointer;
  position: fixed;
  top: 0;
  z-index: 8888;
}
.navList span {
  display: block;
}
.navList div {
  text-align: center;
  float: left;
  line-height: 50px;
  height: 70px;
  width: 70px;
  margin-right: 45px;
  font-size: 16px;
  color: #666666;
  position: relative;
  top: 0;
  transition: top 0.5s;
}
.navList div span:nth-of-type(1) {
  opacity: 1;
  transition: all 0.5s;
}
.navList div span:nth-of-type(2) {
  opacity: 0;
  transition: all 0.5s;
}
.navList div:hover {
  top: -50px;
}
.navList div:hover span:nth-of-type(1) {
  opacity: 0;
}
.navList div:hover span:nth-of-type(2) {
  opacity: 1;
}
.text {
  width: 100%;
  height: 708px;
  margin: 150px 0;
}
.left {
  width: 1242px;
  height: 100%;
  float: left;
  background: url("../../public/imgs/me/banner.png") no-repeat top left;
}
.right {
  width: 585px;
  height: 100%;
  float: left;
  margin-left: 50px;
}
.right > div:nth-of-type(1) {
  font-size: 30px;
  font-weight: bold;
  color: #333333;
  margin-top: 10px;
  margin-bottom: 20px;
}
.right > div:nth-of-type(2) {
  font-size: 16px;
  text-align: justify;
  color: #333333;
  line-height: 30px;
  margin-bottom: 30px;
}

.title {
  width: 1200px;
  margin: 100px auto;
  text-align: center;
}

.title > div:nth-of-type(1) {
  width: 300px;
  height: 1px;
  background: #999;
  margin: 6px auto 44px;
}
.title > div:nth-of-type(2) {
  font-size: 18px;
  color: #666666;
  line-height: 36px;
  text-align: left;
}
.zizhi {
  width: 100%;
  height: 819px;
  overflow: hidden;
}
.seamless-warp img {
  width: 400px;
  margin-left: 23px;
  vertical-align: middle;
  margin-top: 100px;
  margin-bottom: 200px;
}

.line {
  width: 100%;
  height: 1px;
  background: #000;
}
.test {
  letter-spacing: 5px;
  line-height: 30px;
  text-indent: 40px;
  margin-bottom: 30px;
}

.zxxm {
  width: 1920px;
  height: 910px;
  background: url("../../public/imgs/me/xmBg.png") no-repeat center center;
}
.zxxmB1 {
  display: flex;
  width: 1600px;
  margin: 0 auto;
  overflow: hidden;
}
.zxxmB1 > div {
  flex: 1;
  text-align: center;
  font-size: 30px;
  font-weight: 900;
  margin-top: 150px;
}
.zxxmB2 {
  display: flex;
  width: 1300px;
  margin: 0 auto;
  overflow: hidden;
}
.zxxmB2 > div {
  flex: 1;
  text-align: center;
  font-size: 30px;
  font-weight: 900;
  margin-top: 120px;
}
</style>
